import * as React from "react";
import speakers from '../data/speakers/speakers.json'
import talks from '../data/talks.json'
import  "./Laptop.css";
import Talk from "./Talk";

const Event = ({data, speakerId}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const openModal = () => {
        setIsOpen(true)
        console.log("openModal")
    }
    const closeModal = () => {
        setIsOpen(false)
        console.log("closeModal")
    }

    const speaker = speakers[speakerId]
    const talk = talks[data.id]
    const isSession = data.title.includes("Session");
    const isTalk = !(data.title == "Registration" || data.title == "Coffee Break" || data.title == "Social Networking @ 302-308 lounge area" ||
                    data.title == "Session" || data.title == "Opening Remarks" || data.title == "Lunch Break" || data.title == "Short Break for Moving");
    return (
        <>
        { isSession ?
            <div className="session">
                <div className="session-list">
                    <div className="session-time-box">
                        <img className="event-time-icon" src="icons/time.svg"/>
                        <span>&nbsp;</span>
                        <span>{data.startTime}</span><span id="event-time-hyphen">{" - "}</span>{data.endTime == "" ?
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>{data.endTime}</span>}
                    </div>
                    <div className="session-talk-box">
                        <div className="session-title">
                            {data.title}
                            {data.session != "" ? ". " : ""}
                            {data.session}
                        </div>
                        <div className="session-chair">
                            {data.sessionChair != "Noone" ? `Chair: ${speaker.speakerEnglish}`: ""}
                        </div>
                    </div>
                </div>
        </div>
            :
            !isTalk ?
            <div className="special">
                <div className="special-list">
                    <div className="special-time-box">
                        <img className="event-time-icon" src="icons/time-gray.svg"/>
                        <span>&nbsp;</span>
                        <span>{data.startTime}</span><span id="event-time-hyphen">{" - "}</span>{data.endTime == "" ?
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>{data.endTime}</span>}
                    </div>
                    <div className="special-talk-box">
                        {data.title}{data.session != "" ? ": " : ""}{data.session}{data.session != "" ? ` (${speaker.speakerEnglish})`: ""}
                    </div>
                </div>
        </div> :
            <Talk data={data} speakerId={speakerId}/>
        }

        </>
    )
}



export default Event;