import { useState, useRef, useEffect } from 'react';
import * as React from "react";
import {Link} from 'react-scroll'
import "./Mobile.css";
import "./Mobile2.css";
const Header = ({isTop}) => {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (
        <div className={`header ${isTop ? '' : 'sticky'}`} ref={ref}>
            <div className="header-link a">
                <Link to="day1" spy={false} smooth={true} offset={-(height-40)}>Wed., Aug. 30</Link>
            </div>
            <div className="header-link b">
                <Link className="header-link" to="day2" spy={true} smooth={true} offset={-(height-40)}>Thu., Aug. 31</Link>
            </div>
        </div>

    )
}

export default Header;