import * as React from "react";
import speakers from '../data/speakers/speakers.json'
import talks from '../data/talks.json'
import  "./Laptop.css";
import ReactModal from 'react-modal';

const Talk = ({data, speakerId}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const openModal = () => {
        setIsOpen(true)
        console.log("openModal")
    }
    const closeModal = () => {
        setIsOpen(false)
        console.log("closeModal")
    }
    const [speakerIsOpen, setSpeakerOpen] = React.useState(false);
    const openSpeaker = () => {
        setSpeakerOpen(true)
    }
    const closeSpeaker = () => {
        setSpeakerOpen(false)
        console.log("closeModal")
    }

    const speaker = speakers[speakerId]
    const talk = talks[data.id]

    const normal = speakerId != "Noone" ? true : false

    return (
        <div>
            <div className="event">
                <div className="event-list">
                    <div className="event-time-box">
                        <img className="event-time-icon" src="icons/time.svg"/>
                        <span>&nbsp;</span>
                        <span>{data.startTime}</span><span id="event-time-hyphen">{" - "}</span>{data.endTime == "" ?
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>{data.endTime}</span>}
                    </div>
                    {speaker.speakerEnglish != "" ? 
                        <div className="event-speaker-box" onClick={normal ? openSpeaker : null}>
                            {
                                <h3 className={`event-speaker-name ${speakerId != "Noone" ? "" : "no-hover"}`}>
                                    {speaker.speakerEnglish}
                                    {speakerId != "Noone" ? <img className="open-name-icon" src="icons/open-gray.svg"/> : null}
                                </h3>
                            }
                            {speaker.affiliation != "" ? <span className="event-speaker-affiliation">
                                {speaker.affiliation}
                            </span> :
                            null
                            }
                        </div>
                         : null
                    }
                    {
                        data.title != "" ? 
                            <div className={`event-talk-box ${speakerId != "Noone" ? "" : "no-hover"}`} onClick={normal ? openModal : null}>
                                <span>
                                    {data.title}
                                    {speakerId != "Noone" ? <img className="open-talk-icon" src="icons/open-orange.svg"/> : null}
                                </span>
                            </div> 
                            : null
                    }
                </div>
            </div>

            <ReactModal
                isOpen={modalIsOpen}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        transition: 'all .6s'
                    },
                    content: {
                        position: 'absolute',
                        top: '25%',
                        bottom: '20%',
                        right: '10%',
                        left: '10%',
                        color: 'lightsteelblue'
                    }
                }}
            >
                <button onClick={closeModal} className="event-modal-close">
                    <img src="icons/close.svg" alt="Close" />
                </button>

                <div className="event-modal-talk-title">
                    {data.title}
                </div>
                <div className="event-modal-talk">
                    <div className="event-modal-content-title">Talk Abstract</div>
                    {talk.abstract}
                </div>

            </ReactModal>

            <ReactModal isOpen={speakerIsOpen}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        transition: 'all .6s'
                    },
                    content: {
                        position: 'absolute',
                        top: '25%',
                        bottom: '20%',
                        right: '10%',
                        left: '10%',
                        color: 'lightsteelblue'
                    }
                }}>
                <div>
                    <button onClick={closeSpeaker} className="event-modal-close">
                        <img src="icons/close.svg" alt="Close" />
                    </button>
                    {
                    speaker != undefined
                    ? <div>
                        {speaker.image != "" ? <img className="event-modal-image"
                            src={speaker.image} alt="profile"/> : null}
                        <div className="event-modal-speaker-name">
                            {speaker.speakerKorean}
                            {speaker.affiliation != "" ? <div className="event-modal-speaker-affiliation">
                                {`(${speaker.affiliation})`}
                            </div> : null}
                        </div>
                        <div className="event-modal-speaker-contact">
                            {
                                speaker.website != ""
                                ? <a
                                    href={speaker.website}
                                    className="event-modal-speaker-website"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <img
                                        src="icons/website.svg"
                                        alt="Homepage"
                                        height="20px"
                                        width="20px"/>
                                </a>
                                : null
                            }
                            {
                                speaker.email != ""
                                ? <a
                                    href={speaker.email}
                                    className="event-modal-speaker-email"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <img
                                        src="icons/at.svg"
                                        alt="Email"
                                        height="18px"
                                        width="18px"/>
                                </a>
                                : null
                            }
                        </div>
                        </div>
                    : null
                }
                {
                    speaker != undefined
                    ? <div className="event-modal-talk">
                        <div className="event-modal-content-title">Speaker Bio</div>
                        {speaker.bio}
                    </div>
                    : null
                }
                </div>
            </ReactModal>
        </div>
    )
}





export default Talk;