import * as React from "react";
import Event from "./Event";
import data from '../data/schedule.json'
import "./Mobile.css";
import "./Mobile2.css";
const Schedule = (day1, day2) => {
    const arr = [1, 2, 3, 4];
    return (
        <div className="main">
            {
                data.map((node, i) => (
                    <div key={i} id={"day"+(i+1)}>
                        <div className="schedule-header" >
                            <div className="schedule-day">
                                {"Day "}{i+1}
                            </div>
                            <div className="schedule-date">
                                {node.date}
                            </div>
                        </div>
                        <div className="schedule-event">
                        {
                            node.content.map((item) => (
                                <div className="schedule-event-info">
                                {
                                    item.id < 0 ? <div></div> :
                                        <Event key={item.id} data={item} speakerId={item.speakerId}/>
                                }
                                </div>
                            ))
                        }
                        </div>
                    </div>
                ))
            }
        </div>


    )
}



export default Schedule;