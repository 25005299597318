import * as React from "react";
import Lottie from 'lottie-react';
import aiConnectedLottie from "../data/lotties/ai-connected-lottie.json"
import "./Laptop.css";

const Banner = ({data}) => {
    return (
        <div className="site-cover">
            <div className="site-head">
                <div className="site-banner">
                    <img className="site-banner-title" src="images/title.svg" />
                    <img className="site-banner-place-time" src="images/place_time.svg" />
                </div>
                <div style={{display: "flex"}}>
                    <div className="site-register">
                        <button class="register-button" role="button">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdZkA9vs9V95LYp-GVI37NITbwX_fs4sv27_s2owzf1JTWlDw/viewform" target="_blank" 
                                className="register-tag">
                                Register
                            </a>
                        </button>
                        <h className="register-text">*Register for a free sandwich! (~8/27)</h>
                    </div>
                    <div className="site-review">
                        <button class="review-button" role="button">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdA4cBvwjoY8q_jOTeJPVuDN1GqLw77UxKRxDXjBmIwWUkTjw/viewform?usp=send_form" target="_blank" 
                                className="review-tag">
                                Review & Win
                            </a>
                        </button>
                        <h className="review-text">*Write a review and win AirPods!</h>
                    </div>
                </div>
                <div>
                    
                </div>
            </div>
            <Lottie className="site-image" animationData={aiConnectedLottie}/>
        </div>
    )
}

export default Banner;

