import * as React from "react";
import speakers from '../data/speakers/speakers.json'
import talks from '../data/talks.json'
import "./Mobile.css";
import "./Mobile2.css";
import Talk from "./Talk";

const Event = ({data, speakerId}) => {
    const speaker = speakers[speakerId]
    const talk = talks[data.id]
    const isSession = data.title.includes("Session");
    const isTalk = !(data.title == "Registration" || data.title == "Coffee Break" || data.title == "Social Networking @ 302-308 lounge area" ||
                    data.title == "Session" || data.title == "Opening Remarks" || data.title == "Lunch Break"  || data.title == "Short Break for Moving");
    return (
        <>
        { isSession ?
            // session
            <div className="session">
                <div className="session-title">
                        {data.title}
                        {data.session != "" ? ". " : ""}
                        {data.session}
                </div>
                <div className="session-chair">
                    {data.sessionChair != "Noone" ? `Chair: ${speaker.speakerEnglish}`: ""}
                </div>
            </div>
            :
            // specials
            !isTalk ?
            <div className="special">
                <div className="special-time-box">
                    <img className="event-time-icon" src="icons/time-gray.svg"/>
                    <span>&nbsp;</span>
                    <span>{data.startTime}</span><span id="event-time-hyphen">{" - "}</span>{data.endTime == "" ?
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>{data.endTime}</span>}
                </div>
                <div className="special-talk-box">
                        {data.title.includes("Social Gathering") ? "Social Gathering" : data.title}{data.session != "" ? ": " : ""}{data.session}{data.session != "" ? ` (${speaker.speakerEnglish})`: ""}
                </div>
            </div>
            :
            // talks
            <Talk data={data} speakerId={speakerId}/>
        }

        </>
    )
}



export default Event;