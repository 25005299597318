import { useState, useRef, useEffect } from 'react';
import * as React from "react";
import {Link} from 'react-scroll'
import "./Laptop.css";

const Header = ({data, isTop}) => {
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
    })

    return (
        <div className="header" ref={ref}>
            <div className="header-left">
                <a href={data.snu_link}>
                    <img
                        className="header-logo"
                        src={isTop ? "images/snu-white-logo.png" : "images/snu-color-logo.png"}
                        alt={data.snu_title}
                    />
                </a>
                <a href={data.cse_link}>
                    <img
                        className="header-logo"
                        src={isTop ? "images/cse-white-logo.png" : "images/cse-color-logo.png"}
                        alt={data.cse_title}
                    />
                </a>
                <a href={data.ipai_link}>
                    <img
                        className="header-logo"
                        src={isTop ? "images/ipai-white-logo.png" : "images/ipai-color-logo.png"}
                        alt={data.ipai_title}
                    />
                </a>
                <a href="https://ict.snu.ac.kr/" className='ict'>
                    <div className={`ict-korean ${isTop ? 'white':''}`}>서울대학교 컴퓨터연구소</div>
                    <div className={`ict-english ${isTop ? 'white':''}`}>INSTITUTE OF COMPUTER TECHNOLOGY</div>
                </a>
            </div>
            <div className="header-center">
                <Link className="header-link" to="day1" spy={false} smooth={true} offset={-(height-40)}>Wednesday, Aug. 30</Link>
                <div className="header-divider">{"|"}</div>
                <Link className="header-link" to="day2" spy={true} smooth={true} offset={-(height-40)}>Thursday, Aug. 31</Link>
            </div>
            <div className="header-right">
                <a to="/#contact" className="header-link"></a>
            </div>
        </div>
    )
}

export default Header;