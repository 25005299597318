import * as React from "react";
import speakers from '../data/speakers/speakers.json'
import talks from '../data/talks.json'
import "./Mobile.css";
import "./Mobile2.css";
import ReactModal from 'react-modal';
import { Link } from "react-scroll";

const Talk = ({data, speakerId}) => {
    const [talkIsOpen, setTalkOpen] = React.useState(false);
    const toggleTalk = () => {
        setTalkOpen(!talkIsOpen)
    }
    const [speakerIsOpen, setSpeakerOpen] = React.useState(false);
    const toggleSpeaker = () => {
        setSpeakerOpen(!speakerIsOpen)
    }

    const speaker = speakers[speakerId]
    const talk = talks[data.id]

    const normal = speakerId != "Noone" ? true : false;


    const [height, setHeight] = React.useState(0);
    React.useEffect(() => {
        setHeight(document.querySelector(".header").offsetHeight)
    }, document.querySelector(".header"))

    return (
        <div>
            <div className="event" id={speaker.speakerEnglish}>
                <div className="event-list">
                    <div className="event-time-box">
                        <img className="event-time-icon" src="icons/time.svg"/>
                        <span>&nbsp;</span>
                        <span>{data.startTime}</span><span id="event-time-hyphen">{" - "}</span>{data.endTime == "" ?
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span>{data.endTime}</span>}
                    </div>
                    <div className="event-speaker-box" onClick={normal ? toggleSpeaker : null}>
                        <div className={`event-speaker-name ${normal ? "" : "no-hover"}`}>
                            {
                                normal ? (speakerIsOpen ?
                                <img className="toggle-icon" src="icons/down.svg"/>
                                : <img className="toggle-icon" src="icons/right.svg"/>) : null
                            }
                            {speaker.speakerEnglish}
                        </div>
                        {speaker.affiliation != "" ? <span className="event-speaker-affiliation">
                            {speaker.affiliation}
                        </span> :
                            null
                        }

                    </div>
                    {
                        speakerIsOpen &&
                        <div>
                            <div className="event-modal-speaker">
                                {speaker.image != "" ? <img className="event-modal-speaker-image"
                                    src={speaker.image} alt="profile"/> : null}
                                <div className="event-modal-speaker-name">
                                    {speaker.speakerKorean}
                                    {speaker.affiliation != "" ? <div className="event-modal-speaker-affiliation">
                                        {`(${speaker.affiliation})`}
                                    </div> : null}
                                </div>
                                <div className="event-modal-speaker-contact">
                                    {
                                        speaker.website != ""
                                        ? <a
                                            href={speaker.website}
                                            className="event-modal-speaker-website"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <img
                                                src="icons/website.svg"
                                                alt="Homepage"
                                                height="20px"
                                                width="20px"/>
                                        </a>
                                        : null
                                    }
                                    {
                                        speaker.email != ""
                                        ? <a
                                            href={speaker.email}
                                            className="event-modal-speaker-email"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <img
                                                src="icons/at.svg"
                                                alt="Email"
                                                height="18px"
                                                width="18px"/>
                                        </a>
                                        : null
                                    }
                                </div>
                                <div className="event-modal-speaker-bio">
                                    <div className="event-modal-content-title">Speaker Bio</div>
                                    {speaker.bio}
                                </div>
                            </div>
                            <div className="event-close-detail" >
                                <Link to={speaker.speakerEnglish} onClick={toggleSpeaker} spy={false} smooth={'linear'} duration={600} offset={-(height+10)}>Close</Link>
                            </div>
                        </div>
                    }
                    {
                        data.title != "" ?
                        <div className={`event-talk-box ${normal ? "" : "no-hover"}`} onClick={normal ? toggleTalk : null}>
                            {
                                normal ? (talkIsOpen ?
                                    <img className="toggle-icon talk" src="icons/down-orange.svg"/>
                                    : <img className="toggle-icon talk" src="icons/right-orange.svg"/>) : null
                            }
                            {data.title}
                        </div>
                        : null
                    }
                    {talkIsOpen &&
                        <div>
                            <div className="event-modal-talk">
                                <div className="event-modal-content-title">Talk Abstract</div>
                                {talk.abstract}
                            </div>
                            <div className="event-close-detail" >
                                <Link to={speaker.speakerEnglish} onClick={toggleTalk} spy={false} smooth={'linear'} duration={600} offset={-(height+10)}>Close</Link>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* <ReactModal isOpen={speakerIsOpen}
                contentLabel="Example Modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                        transition: 'all .6s'
                    },
                    content: {
                        position: 'absolute',
                        top: '25%',
                        bottom: '20%',
                        right: '10%',
                        left: '10%',
                        color: 'lightsteelblue'
                    }
                }}>
                <div>
                    <button onClick={closeSpeaker} className="event-modal-close">
                        <img src="icons/close.svg" alt="Close" />
                    </button>
                    {
                        speaker != undefined
                        ? <div>
                            {speaker.image != "" ? <img className="event-modal-image"
                                src={speaker.image} alt="profile"/> : null}
                            <div className="event-modal-speaker-name">
                                {speaker.speakerKorean}
                                {speaker.affiliation != "" ? <div className="event-modal-speaker-affiliation">
                                    {`(${speaker.affiliation})`}
                                </div> : null}
                            </div>
                            <div className="event-modal-speaker-contact">
                                {
                                    speaker.website != ""
                                    ? <a
                                        href={speaker.website}
                                        className="event-modal-speaker-website"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img
                                            src="icons/website.svg"
                                            alt="Homepage"
                                            height="20px"
                                            width="20px"/>
                                    </a>
                                    : null
                                }
                                {
                                    speaker.email != ""
                                    ? <a
                                        href={speaker.email}
                                        className="event-modal-speaker-email"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <img
                                            src="icons/at.svg"
                                            alt="Email"
                                            height="18px"
                                            width="18px"/>
                                    </a>
                                    : null
                                }
                            </div>
                        </div>
                        : null
                    }
                    {
                        speaker != undefined
                        ? <div className="event-modal-talk">
                            <div className="event-modal-content-title">Speaker Bio</div>
                            {speaker.bio}
                        </div>
                        : null
                    }
                </div>
            </ReactModal> */}
        </div>
    )
}





export default Talk;