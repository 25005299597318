import React from "react";
import { useMediaQuery } from 'react-responsive'
import Laptop from "./laptop/Laptop.js";
import Mobile from "./mobile2/Mobile.js";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

// Modal.setAppElement('#root');

const DefaultLayout = () => {
    const isMobileDevice = useMediaQuery({
        query: "(max-width: 1150px)",
      });

      // const isTabletDevice = useMediaQuery({
      //   query: "(max-width: 1148px) and (min-width: 1148px)",
      // });

      const isLaptop = !isMobileDevice
      // &&
      // !isTabletDevice
      ;


    return <>
        {isLaptop && <Laptop/>}
        {/* {isTabletDevice && <Tablet/>} */}
        {isMobileDevice && <Mobile/>}
    </>;
};

export default DefaultLayout;
