import React, {useEffect, useRef, useState} from "react";
import { useScrollYPosition } from 'react-use-scroll-position';
import data from '../data/header/header.json';

// Styles
import "./Mobile.css";
import "./Mobile2.css";
import Schedule from "./Schedule";
import Header from "./Header";
import Banner from "./Banner";

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */

// Modal.setAppElement('#root');

const Mobile = () => {
    const scrollY = useScrollYPosition(),
    isTop = scrollY == 0;
    const setColor = () => {
        document.documentElement.style.setProperty('--header-bg', isTop ? '#00000000': 'white');
        document.documentElement.style.setProperty('--header-text', !isTop ? '#101012ff': 'white');
    }

    useEffect(() => {
        setColor();
    }, [isTop])

    const [height, setHeight] = React.useState(0);
    React.useEffect(() => {
        setHeight(document.querySelector(".header").offsetHeight)
    }, document.querySelector(".header"))

    return <>
        <div className="viewport">
            <div className="viewport-top" style={{paddingBottom: !isTop ? 20 : height+20}}>
                <Header isTop={isTop}/>
                <Banner/>
            </div>
            <div className="viewport-middle">
                <Schedule/>
            </div>

            <div className="viewport-bottom">
                <footer className="site-foot">
                    주관:
                    <a href="https://cse.snu.ac.kr/" className="site-foot-link" target="_blank" rel="noopener noreferrer">
                        서울대학교 컴퓨터공학부 10-10 프로젝트
                    </a>,&nbsp;
                    <a href="https://gsai.snu.ac.kr/" className="site-foot-link" target="_blank" rel="noopener noreferrer">
                        협동과정 인공지능 전공
                    </a>,&nbsp;
                    <a href="https://ict.snu.ac.kr/" className="site-foot-link" target="_blank" rel="noopener noreferrer">
                        서울대학교 컴퓨터연구소
                    </a>
                    &nbsp;©&nbsp;{new Date().getFullYear()}
                </footer>
            </div>
        </div>
    </>;
};

export default Mobile;
